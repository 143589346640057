<style lang="less" scoped>
.fengkong {
  background-color: #333333;
  padding: 10px;
  margin: 10px;
  .title {
    margin: 10px 0;
  }
}
</style>

<template>
  <div class="fengkong">
    <van-form label-width="160px">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          readonly
          name="picker"
          :value="form.exchange_name"
          label="交易所"
          placeholder="点击选择"
          right-icon="arrow-down"
          @click="handlerShowPicker('exchange_name')"
      />

      <van-field
          readonly
          name="picker"
          :value="form.sub_account_name"
          label="从"
          placeholder="点击选择"
          right-icon="arrow-down"
          @click="handlerShowPicker('sub_account_id')"
      />
      <van-field
          readonly
          name="picker"
          :value="show_form.primary_account_id"
          label="到"
          placeholder="点击选择子账号"

      />

      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-field label="可归集金额" readonly name="picker" :value="form.rest">
        <template #button>
          <div size="small">{{ form.currency }}</div>
        </template>
      </van-field>



      <div style="margin: 16px">
        <van-button @click="handlerSubmit" :loading="loading" round block type="info" native-type="submit"
        >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import {getAccounts,getAccountRestGuiji,accountGuiji} from "../../api/setting";
import {Notify,Toast} from "vant";

export default {
  name: "AccountTrans",
  props: {

  },
  data() {
    return {
      loading: false,
      form: {
        exchange_name: "",
        primary_account_id: "",
        sub_account_id: "",
        sub_account_name: '',
        currency: '',
        currency_name: '',

        account_type: "",
        rest: "",
        count: 0,
      },
      show_form: {
        primary_account_id: '',
        account_type:""
      },

      checked_field: '',
      checked_list: [],

      accounts: [],

      columns: [],
      showPicker: false,

      exchange_names: [],
      account_types: ["COIN_FUTURE","USDT_FUTURE","SPOT"],
      account_type_names: ["币本位账户","U本位账户","现货账户"],

      symbols: [],
    };
  },
  mounted() {
    this.handlerGetAccountList()
  },
  methods: {

    handlerSubmit(){



      let info = {
        exchange_name: this.form.exchange_name,
        primary_account_id: this.form.primary_account_id,
        sub_account_id: this.form.sub_account_id,
        currency: this.form.currency,
      }

      if(this.loading){
        return;
      }
      this.loading = true
      accountGuiji({
        ...info
      }).then(res => {
        Notify({type:'success',message:res.message})
        this.loading = false
        this.form.count = ''
        this.handlerGetAccountRest()
      })
    },

    handlerGetAccountRest() {
      getAccountRestGuiji({exchange_name: this.form.exchange_name,sub_account_id: this.form.sub_account_id,currency: this.form.currency,primary_account_id: this.form.primary_account_id}).then(res =>{
        // console.log(res)
        this.form.rest = res.data.sub_asset_balances
      })
    },

    handlerShowPicker(e) {
      // console.log(e);
      this.checked_field = e;
      if(e === 'exchange_name'){
        this.columns = this.exchange_names;
      }

      if(e === 'sub_account_id'){
        if(!this.form.exchange_name) {
          Notify({message: '请先选择交易所', type: 'warning'})
          return
        }
        // const item = this.accounts.find(item => item.id === this.form.primary_account_id)
        let list = []
        this.accounts.forEach(item => {
          if(item.exchange_name === this.form.exchange_name){
            list = [...list,...(item.children.map(i=>{
                i.primary_account_name = item.account_name
              return i
            }))]
          }
        })
        this.checked_list =  list;
        this.columns = this.checked_list.map(item => item.account_name);
      }

      if(e === 'currency_name'){
        if(!this.form.sub_account_id) {
          Notify({message: '请先选择子账号', type: 'warning'})
          return
        }
        this.checked_list = this.symbols;
        this.columns = this.symbols.map(item => item.currency_name)

      }


      if(e === 'account_type'){
        this.checked_list = this.account_types
        this.columns = this.account_type_names
      }
      this.showPicker = true;
    },
    onConfirm(e,index) {

      if(this.checked_field === 'exchange_name'){
        this.form.exchange_name = e
        this.form.sub_account_name = ''
        this.form.sub_account_id = ''
        this.form.currency_name = ''
        this.form.primary_account_id = ''
        this.show_form.primary_account_id = ''
      }

      if(this.checked_field === 'sub_account_id'){
        this.form.sub_account_name = e;
        this.form.sub_account_id = this.checked_list[index].id
        this.form.currency = this.checked_list[index].margin_type
        this.form.primary_account_id = this.checked_list[index].primary_id
        this.show_form.primary_account_id = this.checked_list[index].primary_account_name
        this.handlerGetAccountRest()
        // getAccountRestGuiji();
        // getAccountRest(this.form.exchange_name,this.form.primary_account_id,this.form.currency).then(res =>{
        //   // console.log(res)
        //   this.form.rest = res.data.sub_asset_balances
        // })
        // getSymbolPosition(this.form.exchange_name,this.form.sub_account_id).then(res => {
        //   console.log(res)
        //   this.symbols = res.data
        // })
      }

      if(this.checked_field === 'account_type'){
        this.form.account_type = this.checked_list[index]
        this.show_form.account_type = e
      }

      this.showPicker = false;
    },

    handlerGetAccountList(){
      const t = Toast.loading({
        message:'加载中',
        forbidClick: true,
        duration: 10000,
      })
      getAccounts().then(res => {
        this.accounts = res.data;
        this.exchange_names =  Array.from(new Set(res.data.map(item => item.exchange_name)))
        t.clear()
      })

    }
  },
};
</script>
